import { Link } from "gatsby"
import React from "react"
import Headroom from './CustomHeadroom'
import Icon from '../assets/images/svg/o_logo.svg'

export default (props) => {
  return  (
    <div className="header-wrapper">
      <header>
          <div className={`w-wrapper`}>
          <Headroom>
          <div className="w-wrapper-top">
            <h2 className={(props.activePage === "Who" ? "active " : "") + "layout-link who"} style={{ margin: 0 }}>
              <Link to="/who">
                Who
              </Link>
            </h2>
            <img className="logo" src={Icon} height="50" width="50" alt="Axel Olson Logo" />
            <h2 className={(props.activePage === "What" ? "active " : "") + "layout-link what"} style={{ margin: 0 }}>
              <Link to="/">
                What
              </Link>
            </h2>
          </div>
        </Headroom>
        <Headroom disableInlineStyles>
          <div className="w-wrapper-bottom">
            <h2 className={(props.activePage === "When" ? "active " : "") + "layout-link when"} style={{ margin: 0 }}>
              <Link to="/when">
                When
              </Link>
            </h2>
            <h2 className={(props.activePage === "Where" ? "active " : "") + "layout-link where"} style={{ margin: 0 }}>
              <Link to="/where">
                Where
              </Link>
            </h2>
          </div>
        </Headroom>
          </div>
      </header>
    </div>
  )
}

export const imageQuery = graphql`
  query {
    file(relativePath: {eq: "o_logo.svg"}) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          srcSet
        }
      }
    }
  }
`
