/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "../assets/less/layout.less"
import "../assets/less/global.less"
// import { useDispatch } from 'react-redux'


const Layout = (props) => {
  // const dispatch = useDispatch()
  // dispatch({type: 'SET_ACTIVEPROJECT_OPACITY', opacity: 1})

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      file(relativePath: {eq: "o_logo.png"}) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title}
              coordinates={props.coords}
              setCoordinates={props.setCoordinates}
              logo={data.file.childImageSharp}
              activePage={props.page}/>
      <div className="site-wrapper">
        <main>{props.children}</main>
      </div>
    </>
  )
}

// export default connect(useDispatch)(Layout)
export default Layout
